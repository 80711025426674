<!-- eslint-disable max-len -->
<template>
  <cookies />

  <header class="fixed z-10 top-0 inset-x-0 bg-white bg-opacity-80 backdrop-blur-lg">
    <div class="container">
      <div class="py-15 lg:py-25 flex justify-between">
        <ul class="flex space-x-20 text-sm lg:text-base lg:space-x-50 items-center font-medium text-gray-500">
          <li>
            <a href="#home">
              <img
                src="/img/logo.svg"
                alt="Clubee"
                title="Clubee"
                class="w-[125px] lg:w-auto"
              >
            </a>
          </li>
          <li class="hidden md:flex">
            <a
              href="#club"
              class="hover:no-underline hover:text-black transition"
            >voor jouw club</a>
          </li>
          <li class="hidden md:flex">
            <a
              href="#leden"
              class="hover:no-underline hover:text-black transition"
            >voor clubleden</a>
          </li>
          <li class="hidden md:flex">
            <a
              href="#tarieven"
              class="hover:no-underline hover:text-black transition"
            >tarieven</a>
          </li>
          <li class="hidden md:flex">
            <a
              href="#contact"
              class="hover:no-underline hover:text-black transition"
            >contact</a>
          </li>
        </ul>

        <ul class="flex space-x-10 ml-10 items-center font-semibold text-black">
          <li>
            <a
              href="#contact"
              class="flex items-center text-sm lg:text-base whitespace-nowrap px-15 lg:px-20 h-40 lg:h-50 rounded-full bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
            >probeer gratis</a>
          </li>
          <li>
            <a
              href="https://admin.clubee.nl/"
              title="Inloggen"
              class="flex items-center justify-center h-40 lg:h-50 w-40 lg:w-50 rounded-full bg-black bg-opacity-5 hover:bg-opacity-10 transition"
            >
              <svg
                width="18"
                height="20"
                viewBox="0 0 18 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Inloggen</title>
                <path
                  d="M9 13v2m-6 4h12a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2zM13 9V5a4 4 0 0 0-8 0v4h8z"
                  stroke="#000"
                  stroke-width="2"
                  fill="none"
                  fill-rule="evenodd"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </header>
  <router-view />
</template>

<script>
import { defineComponent } from 'vue';
import Cookies from '@/components/Cookies.vue';

export default defineComponent({
  components: {
    Cookies,
  },
});
</script>
