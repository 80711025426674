import { createApp } from 'vue';
import router from '@/plugins/router';
import gtm from '@/plugins/gtm';
import tidio from '@/plugins/tidio';
import App from '@/App.vue';
import '@/assets/css/styles.css';

const app = createApp(App)
  .use(router)
  .use(gtm)
  .use(tidio, { appKey: '0rxlsldmyesbts8i2sjwveqhghzozyep' });

router.isReady().then(() => {
  app.mount('#app');
});

window.snapSaveState = () => {
  document.querySelector('#app').setAttribute('data-server-rendered', 'true');
};
