import { createRouter, createWebHistory } from 'vue-router';
import routes from '@/routes';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to) => window.tidioChatApi && window.tidioChatApi.display(to.meta.showChat));

export default router;
