import Home from '@/views/Home.vue';

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      showChat: true,
    },
  },
];
