<!-- eslint-disable max-len, vue/max-len -->
<template>
  <div class="hidden fixed z-20 bottom-0 right-0 m-20 rounded-xl bg-white bg-opacity-80 text-gray-500 border border-gray-100 text-xs w-[90%] max-w-md backdrop-blur-lg p-25">
    <p>
      Wij, en derde partijen, maken op onze website gebruik van cookies.
      Wij gebruiken cookies voor het bijhouden van statistieken, om jouw voorkeuren op te slaan, maar ook voor marketingdoeleinden.
      Door op "Accepteren" te klikken, ga je akkoord met het gebruik van alle cookies zoals omschreven in onze
      <a
        href="/assets/200909-Privacy-cookieverklaring-Website-Clubee.pdf"
        target="_blank"
        class="text-black underline hover:no-underline"
      >privacy- en cookieverklaring</a>.
      Kies je "Weigeren", dan gebruiken we alleen functionele cookies.
    </p>
    <button
      type="button"
      class="mt-20 mr-10 inline-flex items-center text-black font-semibold text-sm whitespace-nowrap px-15 h-40 rounded-full bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
      @click="accept"
    >
      accepteren
    </button>
    <button
      type="button"
      class="mt-20 inline-flex items-center text-black font-semibold text-sm whitespace-nowrap hover:underline"
      @click="deny"
    >
      weigeren
    </button>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Cookie from '@/cookie';
import EventBus from '@/eventBus';

export default defineComponent({
  storageKey: 'cookiePreferences',
  mounted() {
    if (!Cookie.get(this.$options.storageKey)) {
      this.$el.classList.remove('hidden');
    }

    EventBus.on('cookies.show', () => {
      this.$el.classList.remove('hidden');
    });
  },
  methods: {
    /**
     * Deny cookies.
     */
    deny() {
      Cookie.set(this.$options.storageKey, 'denied');
      this.$el.classList.add('hidden');
      EventBus.emit('cookies.denied');
    },
    /**
     * Accept cookies.
     */
    accept() {
      Cookie.set(this.$options.storageKey, 'accepted');
      this.$el.classList.add('hidden');
      EventBus.emit('cookies.accepted');
    },
  },
});
</script>
