<!-- eslint-disable max-len -->
<template>
  <p class="text-gray-500">
    Deze inhoud kan niet worden weergegeven omdat de cookies niet zijn geaccepteerd.
  </p>
  <button
    class="inline-flex mt-20 font-semibold px-20 lg:px-25 py-10 lg:py-15 rounded-full bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
    @click="settings"
  >
    cookie instellingen
  </button>
</template>

<script>
import { defineComponent } from 'vue';
import EventBus from '@/eventBus';

export default defineComponent({
  methods: {
    /**
     * Show settings.
     */
    settings() {
      EventBus.emit('cookies.show');
    },
  },
});
</script>
