import { createGtm } from '@gtm-support/vue-gtm';
import router from '@/plugins/router';

export default createGtm({
  id: 'GTM-WP2WQ7T',
  queryParams: {},
  defer: false,
  compatibility: false,
  nonce: null,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  ignoredViews: [],
  trackOnNextTick: false,
});
